<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">设备预警信息</h1>
    </div>
    <a-card :bordered="false">
      <Table></Table>
    </a-card>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/PageLayout'

import Table from './Table'
export default {
  name: 'List',
  components: { PageLayout, Table },
  mixins: [],
  data() {
    return {}
  },
  methods: {},
}
</script>
